<template>
  <div class="row p-0 m-0 mb-5 mt-5">
    <Member :member="member" v-for="member in members" :key="member._id"/>
    <MemberForm />
  </div>
</template>

<script>
import axios from 'axios'
import Member from '../components/Member'
import MemberForm from '../components/MemberForm'
export default {
  components: {
    Member,
    MemberForm
  },
  data() {
    return {
      members: []
    }
  },
  mounted() {
    try {
      axios.get("api/fetchMembers").then((res) => {
        if (!res.data) return false
        res.data.forEach((member) => {
          this.members.push(member)
        })
      }).catch(error => this.$store.commit("LOG_EVENT", error))
    } catch (error) {
      this.$store.commit("LOG_EVENT", (error))
    }
  }
}
</script>
