<template>
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 m-0 mb-4">
    <div class="card shadow h-100">
      <img :src="teacher.image" class="card-img-top" alt="member-image" height="500" style="object-fit: cover;">
      <div class="card-body">
        <h5 class="card-title">{{teacher.name}}</h5>
        <span class="text-muted">{{teacher.bio}} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacher: Object,
  }
}
</script>