<template>
  <div>
    <div class="row m-0 me-2 ms-2 mb-5">
      <div class="container p-0 m-0">
      <div class="alert alert-success alert-dismissible fade show" role="alert">
          Σας καλωσορίζουμε στην ιστοσελίδα της <strong>Ελληνικής Κοινότητας Βέρνης</strong>. Εδώ θα βρείτε πληροφορίες σχετικά με την Κοινότητα, το <router-link to="/school">Ελληνικό Σχολείο</router-link> και την <router-link to="/church">Εκκλησία</router-link>.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <br><br>
          Για να <strong>γίνετε μέλος</strong> ή να <strong>επικοινωνήσετε </strong>μαζί μας χρησιμοποιήστε τις διαδικτυακές φόρμες της ιστοσελίδας. Ως εθελοντικό project, η σελίδα βρίσκεται σε διαρκή εξέλιξη. Για σχετικές ερωτήσεις, προτάσεις ή αν θέλετε να διαφημιστείτε, επικοινωνήστε μαζί μας.
         <br><br>
         <button class="btn btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#contactModal">Επικοινωνία</button>
         <button class="btn btn btn-outline-success me-2" data-bs-toggle="modal" data-bs-target="#membershipForm">Γίνε μέλος</button>
      </div>
    </div>
    <HomeAccordion />
    <News />
    <Events />
  </div>
</template>

<script>
import HomeAccordion from '../components/HomeAccordion'
import News from '../components/News'
import Events from '../components/Events'
export default {
  components: {
    HomeAccordion,
    News,
    Events,
  }
}
</script>
