<template>
<div class="modal fade" tabindex="-1" id="memberForm">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add member</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" v-model="name" class="form-control" placeholder="Name">
        </div>
        <div class="mb-3">
          <input type="text" v-model="image" class="form-control" placeholder="Image URL-Link">
        </div>
        <div class="mb-3">
          <input type="text" v-model="job" class="form-control" placeholder="Job Description">
        </div>
        <div class="mb-3">
          <input type="text" v-model="address" class="form-control" placeholder="Address">
        </div>
        <div class="mb-3">
          <input type="text" v-model="phone" class="form-control" placeholder="Phone">
        </div>
        <div class="mb-3">
          <input type="text" v-model="email" class="form-control" placeholder="Email">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button @click="addMember" type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      name: '',
      image: "",
      email: "",
      job: "",
      address: "",
      phone: ""
    }
  },
  methods: {
    addMember() {
      try {
        if (this.name == '' || this.information == "") return false
        if (!localStorage.getItem("token")) return false
        axios.post("api/addMember", {
          name: this.name,
          image: this.image,
          email: this.email,
          job: this.job,
          address: this.address,
          phone: this.phone,
          token: localStorage.getItem("token")
        }).catch(error => this.$store.commit("LOG_EVENT", error))
        this.name = ''
        this.image = ""
        this.email = ''
        this.phone = ''
        this.job = ''
        this.address = ''
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
