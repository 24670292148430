<template>
<div class="modal fade" tabindex="-1" id="adModal">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create add</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <select v-model="type" class="form-select" aria-label="Default select example">
              <option selected>What do you advertise?</option>
              <option value="service">Service</option>
              <option value="product">Product</option>
            </select>
          </div>
          <div class="mb-3">
            <input v-model="name" type="text" class="form-control" placeholder="What is your name/company?">
          </div>
          <div class="mb-3">
            <input v-model="image" type="text" class="form-control" placeholder="Image URL-Link">
          </div>
          <div class="mb-3">
            <input type="text" v-model="link" class="form-control" placeholder="Link to relevant webpage">
          </div>
          <div class="mb-3">
            <markdown-editor v-model="information"></markdown-editor>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button @click="sendNewAdd" type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      name: '',
      link: '',
      image: "",
      type: '',
      information: ''
    }
  },
  methods: {
    async sendNewAdd() {
      try {
        if (this.name == '' || this.information == "") return false
        if (!localStorage.getItem("token")) return false
        await axios.post("api/addNewAd", {
          name: this.name,
          image: this.image,
          link: this.link,
          type: this.type,
          information: this.information,
          token: localStorage.getItem("token")
        }).catch(error => this.$store.commit("LOG_EVENT", error))
        this.name = ''
        this.link = ''
        this.image = ''
        this.type = ''
        this.information = ''
        window.location.reload();
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
