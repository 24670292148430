<template>
  <div class="modal fade" tabindex="-1" id="newsModal">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Post News</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <input v-model="title" type="text" class="form-control" placeholder="Title">
          </div>
          <div class="mb-3">
            <input v-model="image" type="text" class="form-control" placeholder="Image URL (link)">
          </div>
          <div class="mb-3">
            <markdown-editor v-model="content"></markdown-editor>
          </div>
          <div class="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="general" v-model="relevance">
              <label class="form-check-label" for="inlineRadio1">Κοινότητα</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="school" v-model="relevance">
              <label class="form-check-label" for="inlineRadio2">Σχολείο</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="church" v-model="relevance">
              <label class="form-check-label" for="inlineRadio3">Εκκλησία</label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button @click="sendNewsPost" type="button" class="btn btn-primary">Post</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      valid: true,
      title: "",
      image: "",
      content: "",
      relevance: "general",
      date: new Date().toLocaleDateString("el-GR")
    }
  },
  methods: {
    sendNewsPost() {
      try {
        if (this.title == '' || this.content == "") return false
        if (!localStorage.getItem("token")) return false
        axios.post("api/addNewsPost", { title: this.title, content: this.content, image: this.image, date: this.date, relevance: this.relevance, token: localStorage.getItem("token") }).catch(error => this.$store.commit("LOG_EVENT", error))
        this.title = ''
        this.image = ""
        this.relevance = "general"
        this.content = ''
        this.date = ''
        window.location.reload();
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
