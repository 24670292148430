<template>
  <div class="modal fade" tabindex="-1" id="eventModal">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Event</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <input type="text" v-model="title" placeholder="Event title" class="form-control">
          </div>
          <div class="mb-3">
            <input v-model="image" type="text" class="form-control" placeholder="Image URL (link)">
          </div>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Year" v-model="date.year">
            <input type="text" placeholder="Month" class="form-control" v-model="date.month">
            <input type="text" placeholder="Day" class="form-control" v-model="date.day">
            <input type="text" placeholder="Hour" class="form-control" v-model="date.hour">
            <input type="text" placeholder="Minute" class="form-control" v-model="date.minute">
          </div>
          <div class="mb-3">
            <input type="text" v-model="address" class="form-control" placeholder="Add address">
          </div>
          <div class="mb-3">
            <markdown-editor v-model="information"></markdown-editor>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button @click="sendNewEvent" type="button" class="btn btn-primary">Post</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      title: "",
      image: "",
      date: {},
      address: "",
      information: ''
    }
  },
  methods: {
    sendNewEvent() {
      try {
        let timestamp = new Date(this.date.year, this.date.month-1, this.date.day, this.date.hour, this.date.minute).getTime()
        if (this.date == '' || this.address == "" || this.information == "") return false
        if (!localStorage.getItem("token")) return false
        axios.post("api/addNewEvent", { title: this.title, date: this.date, timestamp: timestamp, image: this.image, address: this.address, information: this.information, token: localStorage.getItem("token") }).catch(error => this.$store.commit("LOG_EVENT", error))
        this.title = ""
        this.date = ''
        this.address = ''
        this.information = ''
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
