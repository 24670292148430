import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: false,
    currentPost: '',
    currentEvent: '',
    currentLink: '',
    news: [],
    links: [],
    events: []
  },
  mutations: {
    INCREMENT_LIKES(state, id) {
      state.news.forEach((post) => {
        if (post._id === id) post.likes++
      })
      state.events.forEach((post) => {
        if (post._id === id) post.attends++
      })
      state.links.forEach((post) => {
        if (post._id === id) post.likes++
      })
    },
    LOG_EVENT(state, {message, stack}) {
      try {
        axios.post("/api/logger", { message: message, stack: stack, location: "client" })
      } catch {
        console.log("Could not log event")
      }
    },
    SET_AUTH(state, current) {
      state.auth = current
    },
    SET_EVENTS(state, event) {
      state.events = event
    },
    SET_NEWS(state, news) {
      state.news = news
    },
    SET_LINKS(state, links) {
      state.links = links
    }
  },
  actions: {
    FETCH_NEWS({commit}) {
      axios.get("/api/fetchAllNewsPosts").then(({data}) => {
        commit("SET_NEWS", data)
      }).catch((error) => {
        commit("LOG_EVENT", error)
      })
    },
    FETCH_LINKS({commit}) {
      axios.get("/api/fetchAds").then(({data}) => {
        commit("SET_LINKS", data)
      }).catch((error) => {
        commit("LOG_EVENT", error)
      })
    },
    FETCH_EVENTS({commit}) {
      axios.get("/api/fetchEvents").then(({data}) => {
        commit("SET_EVENTS", data)
      }).catch((error) => {
        commit("LOG_EVENT", error)
      })
    }
  }
})
