<template>
  <div v-if="link" class="row p-0 m-0 mb-5">
    <div>
      <h3>{{link.name}}</h3>
      <img :src="link.image" alt="news-post-image" style="max-width: 100%;">
      <MarkdownItVue class="md-body mt-4" :content="link.information"/>
      <p @click="like" class="btn btn-sm border bg-white mt-4">&#128077; {{link.likes}}</p>
      <router-link to="/" class="float-start float-end mt-4">Πίσω στην αρχική</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'
export default {
  components: {
    MarkdownItVue
  },
  computed: {
    link() {
      return this.$store.state.links.find(link => link._id === this.$route.params.id)
    }
  },
  mounted() {
    try {
      window.scrollTo(0, 0)
    } catch {
      console.log("Could not scroll up page.")
    }
  },
  methods: {
    like() {
      try {
        if (localStorage.getItem(`liked-${this.link._id}`)) return false
        localStorage.setItem("liked-"+this.link._id, "true")
        this.$store.commit("INCREMENT_LIKES", this.link._id)
        this.$root.$emit("increment_link_likes", this.link._id)
        axios.post("/api/likeLink", { linkId: this.link._id }).catch(error => this.$store.commit("LOG_EVENT", error))
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
