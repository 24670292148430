<template>
<div>
<form v-if="auth" class="form border border shadow-sm p-3 mb-5" v-on:submit="addEvent">
  <h3>Add Church Event</h3>
  <div class="mb-3">
    <input type="text" v-model="post.date" class="form-control" placeholder="Date" required/>
  </div>
  <div class="mb-3">
    <input type="text" v-model="post.time" class="form-control" placeholder="Time" required/>
  </div>
  <div class="mb-3">
    <input type="text" v-model="post.location" class="form-control" placeholder="Location" required/>
  </div>
  <button class="btn btn-primary" type="submit">Submit</button>
</form>
  

  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Ημέρα</th>
        <th scope="col">Ώρα</th>
        <th scope="col">Τοποθεσία</th>
      </tr>
    </thead>
    <tbody v-for="post in posts" v-bind:key="post._id">
        <ChurchEvent :post="post" />
    </tbody>
  </table>
  </div>
</template>

<script>
import axios from 'axios'
import ChurchEvent from './ChurchEvent.vue';
export default {
  computed: {
    auth () {
      return this.$store.state.auth;
    }
  },
  components: { ChurchEvent },
  data() {
    return {
      posts: [],
      post: {
        date: "",
        time: "",
        location: ""
      }
    }
  },
  mounted() {
    this.fetchPosts()
  },
  methods: {
    async fetchPosts() {
      const result = await axios.get("/api/church-events");
      this.posts = result.data;
    },
    async addEvent() {
      await axios.post("/api/church-event", this.post)
      window.location.reload()
    }
  }
}
</script>