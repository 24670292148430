<template>
  <div v-if="event" class="row p-0 m-0 mb-5">
    <div>
      <h3>{{event.title}}</h3>
      <p class="text-muted">στις {{event.date.day}}/{{event.date.month}}/{{event.date.year}}, {{event.date.hour}}:{{event.date.minute}} στο {{event.address}}</p>
      <img :src="event.image" alt="news-post-image" style="max-width: 100%;">
      <MarkdownItVue class="md-body mt-4" :content="event.information"/>
      <p @click="attend" class="btn btn-sm border bg-white mt-4">&#128070; {{event.attends}}</p>
      <router-link to="/" class="float-start float-end mt-4">Πίσω στην αρχική</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'
export default {
  components: {
    MarkdownItVue
  },
  computed: {
    event() {
      return this.$store.state.events.find(event => event._id === this.$route.params.id)
    }
  },
  mounted() {
    try {
      window.scrollTo(0, 0)
    } catch {
      console.log("Could not scroll up page.")
    }
  },
  methods: {
    attend() {
      try {
        if (localStorage.getItem(`attend-${this.event._id}`)) return false
        localStorage.setItem("attend-"+this.event._id, "true")
        this.$store.commit("INCREMENT_LIKES", this.event._id)
        axios.post("/api/addNewAttend", { eventId: this.event._id }).catch(error => this.$store.commit("LOG_EVENT", error))
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
