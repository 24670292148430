<template>
    <div class="row p-0 m-0">
      <h3 class="mb-4">Νέα Εκκλησίας</h3>
      <!-- placeholder when no school news -->
      <div v-if="newsPosts.length == 0" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 m-0 mb-4" style="height: 500px !important;">
        <div class="card shadow h-100">
          <img src="https://i.imgur.com/5RPEq91.jpg" class="card-img-top" alt="event-image" height="220" style="object-fit: cover;">
          <div class="card-body">
            <h5 class="card-title">Κανένα νέο ακόμα</h5>
            <div class="card-text mt-4">
              Εδώ θα βρείτε όλα τα νέα της Εκκλησίας.
            </div>
          </div>
        </div>
      </div>

      <!-- dynamic -->
      <NewsPost :post="post" v-for="post in rendered" :key="post._id"/>
      <NewsForm />
      <nav aria-label="...">
        <ul class="pagination float-end">
          <li v-if="this.newsPosts.length > 2" @click="setList(1)" class="page-item active" id="churchFirst"><a class="page-link" type="button">1</a></li>
          <li v-if="this.newsPosts.length > 2" @click="setList(2)" class="page-item" id="churchSecond"><a class="page-link" type="button">2</a></li>
          <li v-if="this.newsPosts.length > 4" @click="setList(3)" class="page-item" id="churchThird"><a class="page-link" type="button">3</a></li>
        </ul>
      </nav>
    </div>
</template>

<script>
import axios from 'axios'
import NewsForm from '../components/NewsForm'
import NewsPost from '../components/NewsPost'
export default {
  computed: {
    auth() {
      return this.$store.state.auth
    }
  },
  components: {
    NewsForm,
    NewsPost,
  },
  data() {
    return {
      newsPosts: [],
      rendered: [],
    }
  },
  methods: {
    setList(num) {
      try {
        var current, churchFirst, churchSecond, churchThird
        if (this.newsPosts.length > 4) {
          churchFirst = document.getElementById("churchFirst")
          churchSecond = document.getElementById("churchSecond")
          churchThird = document.getElementById("churchThird")
          if (num === 2) {
            churchFirst.classList.remove("active")
            churchThird.classList.remove("active")
            churchSecond.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(2,4)
          } else if (num === 1) {
            churchSecond.classList.remove("active")
            churchThird.classList.remove("active")
            churchFirst.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(0,2)
          } else if (num === 3) {
            churchSecond.classList.remove("active")
            churchThird.classList.add("active")
            churchFirst.classList.remove("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(4,6)
          }
        } else if (this.newsPosts.length > 2 && this.newsPosts.length <= 4) {
          churchFirst = document.getElementById("churchFirst")
          churchSecond = document.getElementById("churchSecond")
          if (num === 2) {
            churchFirst.classList.remove("active")
            churchSecond.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(2,4)
          } else if (num === 1) {
            churchSecond.classList.remove("active")
            churchFirst.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(0,2)
          } else if (num === 3) {
            churchSecond.classList.remove("active")
            churchFirst.classList.remove("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(4,6)
          }
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  },
  mounted() {
    try {
      axios.get("api/fetchAllNewsPosts").then((res) => {
        if (!res.data) return false
        res.data.forEach((post) => {
          if (post.relevance === "church") {
            this.newsPosts.push(post)
          }
        })
        this.rendered = this.newsPosts.slice(0,2)
      }).catch(error => this.$store.commit("LOG_EVENT", error))
    } catch (error) {
      this.$store.commit("LOG_EVENT", (error))
    }
  }
}
</script>
