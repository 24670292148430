<template>
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 m-0 mb-4" style="height: 500px !important;">
    <div class="card shadow h-100">
      <img v-if="event.image" :src="event.image" class="card-img-top" alt="event-image" height="220" style="object-fit: cover;">
      <div class="card-body">
        <h5 class="card-title">{{event.title}}</h5>
        <span class="text-muted">στις {{event.date.day}}/{{event.date.month}}/{{event.date.year}}, {{event.date.hour}}:{{event.date.minute}} στο {{event.address}}</span>
        <div class="card-text mt-4">
          {{plainText.substring(0, 120)}} <span @click="$root.$emit('scrollTop')"><router-link :to="'/events/'+event._id">...περισσότερα</router-link></span>
        </div>
      </div>
      <div class="card-footer bg-white border-white">
        <span @click="attend" class="btn btn-sm border float-start">&#128070; {{event.attends}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import removeMD from 'remove-markdown'
import axios from 'axios'
export default {
  data() {
    return {
      plainText: removeMD(this.event.information)
    }
  },
  props: {
    event: Object,
  },
  mounted() {
    try {
      window.scrollTo(0, 0)
    } catch {
      console.log("Could not scroll up page.")
    }
  },
  methods: {
    setCurrentEvent() {
      this.$store.commit("SET_CURRENT_EVENT", { current: this.event })
    },
    attend() {
      try {
        if (localStorage.getItem(`attend-${this.event._id}`)) return false
        localStorage.setItem("attend-"+this.event._id, "true")
        this.$store.commit("INCREMENT_LIKES", this.event._id)
        this.event.attends++
        axios.post("/api/addNewAttend", { eventId: this.event._id }).catch(error => this.$store.commit("LOG_EVENT", error))
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
