<template>
<div class="modal fade" id="administratorControl" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Control Panel </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button @click="getLogs(), $root.$emit('toggle', false)" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <h5>Logs</h5>
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body" style="overflow-x: scroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Message</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    <LogEntry v-for="log in logs" :entry="log" :key="log._id" />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button @click="getConnections" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <h5>Connections</h5>
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body" style="overflow-x: scroll">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Location</th>
                      <th scope="col">Machine</th>
                      <th scope="col">IP</th>
                      <th scope="col">Visits</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="con in connections" :key="con._id">
                      <td>{{con.day + ", " + con.time}}</td>
                      <td>{{con.country + ", " + con.city}}</td>
                      <td>{{con.browser + ", " + con.desktop}}</td>
                      <td>{{con.ip + ", " + con.coordinates}}</td>
                      <td>{{con.visits}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// import JsonViewer from 'vue-json-viewer'
import LogEntry from '../components/LogEntry'
import axios from 'axios'
export default {
  components: {
    // JsonViewer
    LogEntry
  },
  data() {
    return {
      logs: [],
      connections: [],
    }
  },
  methods: {
    getLogs() {
      try {
        axios.post("/api/logs", {
          token: localStorage.getItem("token")
        }).then(({
          data
        }) => {
          this.logs = []
          if (!data) return false
          data.forEach((log) => {
            this.logs.push(log)
          })
        }).catch(error => this.$store.commit("LOG_EVENT", error))
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    getConnections() {
      try {
        axios.post("/api/connections", {
          token: localStorage.getItem("token")
        }).then(({
          data
        }) => {
          this.connections = []
          if (!data) return false
          data.forEach((con) => {
            this.connections.push(con)
          })
        }).catch(error => this.$store.commit("LOG_EVENT", error))
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
