<template>
  <div>
    <div class="row m-0 me-2 ms-2 mb-5">
      <div class="container p-0 m-0">

          Η <strong>Ελληνική Ορθόδοξη Εκκλησία Βέρνης</strong>, που ιδρύθηκε το έτος <strong>1962</strong>,
          μετονομάστηκε το έτος <strong>1972</strong> σε <strong>Ελληνική Κοινότητα Βέρνης</strong>, γερμανικά
          <strong>Griechische Gemeinde Bern</strong> και γαλλικά <strong>communauté Hellenique de Berne</strong>.
          Έτσι υπάρχει και λειτουργεί από το έτος 1972 με έδρα τη Βέρνη της Ελβετίας.
          <br><br>
          Οι λειτουργίες παρέχονται <strong>μια φορά το μήνα</strong> συνήθως την <strong>τελευταία Κυριακή</strong>. Επόμενες λειτουργίες:

          <br><br>
          <TableChurch />
          <br>
          Οι Θείες Λειτουργίες τελούνται από τις 10.00 μέρχι τις 13.00 περίπου. Μετά την Λειτουργία
          (υπό κανονικές συνθήκες) προσφέρεται καφές και γλυκά και μετά ακολουθεί το συμμάζεμα
          και η καθαριότητα της Εκκλησίας, ώστε να αφήσουμε το χώρο περίπου στις 14.00.
          Η Ελληνική κοινότητα
          Βέρνης ενημερώνει με <strong>ταχυδρομική αποστολή</strong> και <strong>αναρτήσεις στην ιστοσελίδα</strong> τους πιστούς
          της ενορίας για το πρόγραμμα των ακολουθιών.
          Τις ανάγκες της ενορίας εξυπηρετεί με συνεργασία της ελληνική κοινότητας Βέρνης, ο <strong>πρεσβύτερος Στέφανος Αθανασίου</strong>.
          Για περισσότερες πληροφορίες για την εκτέλεση των λειτουργιών επικοινωνήστε μαζί μας.
          <br><br>
         <button class="btn btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#contactModal">Επικοινωνία</button>
        </div>
      </div>
      <div class="accordion accordion-flush p-0 mb-5" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
          Τοποθεσία
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body p-0 m-0 mb-4">
          <LocalMap :location="{center: [7.424978962059646, 46.94812659437085], where: [ 7.424036044237296, 46.9484355697557], legend: {name: 'Inselkappele', strasse: 'Freiburgstrasse 24', plz: '3018 Bern'}}" />
        </div>
      </div>
    </div>
      </div>
      <ChurchNews />
  </div>
</template>

<script>
import ChurchNews from '../components/ChurchNews'
import TableChurch from '../components/TableChurch'
import LocalMap from '../components/LocalMap'
export default {
  components: {
    LocalMap,
    ChurchNews,
    TableChurch
  },
}
</script>
