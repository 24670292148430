<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-light bg-white shadow-sm border-bottom">
  <div class="container">
    <router-link class="navbar-brand p-0 mt-0 mb-0" to="/" style="color: inherit; text-decoration: none;"><img class="navbar-brand" height="100" src="@/assets/grgbLogo.png" alt="Logo"/>
</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" type="button" aria-current="page"><router-link to="/" style="color: inherit; text-decoration: none;">Αρχική</router-link></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" type="button" aria-current="page"><router-link to="/school" style="color: inherit; text-decoration: none;">Σχολείο <span class="badge bg-primary">news</span></router-link></a>
        </li>

        <li class="nav-item">
          <a class="nav-link" aria-current="page" type="button"><router-link to="/church" style="color: inherit; text-decoration: none;">Εκκλησία <span class="badge bg-primary">news</span></router-link></a>
        </li>
      </ul>
      <span class="navbar-text">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <button data-bs-toggle="modal" data-bs-target="#contactModal" type="button" class="btn btn-outline-success me-3 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3">Επικοινωνία</button>
          </li>
          <li class="nav-item">
            <button type="button" data-bs-toggle="modal" data-bs-target="#membershipForm" class="btn btn-outline-primary me-3 mb-xl-0 mb-lg-0 mb-md-3 mb-sm-3">Εγγραφή</button>
          </li>
          <li class="nav-item">
            <a href="https://www.facebook.com/groups/grgebe"><button class="btn btn-outline-primary">Facebook</button></a>
          </li>
        </ul>
      </span>
    </div>
  </div>
</nav>
</template>

<script>
export default {

}
</script>
