<template>
<div class="modal fade" id="adminModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Είσοδος Διαχειριστή</h5>
        <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
           <input @input="checkName" v-model="name" ref="adminName" type="text" class="form-control" id="dddd" placeholder="Όνομα">
           <label for="dddd">Όνομα</label>
           <div v-if="nameValidator" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
        </div>
        <div class="form-floating mb-3">
           <input @input="checkPassword" v-model="password" ref="adminPass" type="password" class="form-control" id="aaaa" placeholder="Όνομα">
           <label for="aaaa">Κωδικός</label>
           <div v-if="passValidator" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
        </div>
        <div v-if="feedback" class="alert alert-primary alert-dismissible fade show" role="alert">
            {{feedback}}
          <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
      <div class="modal-footer">
        <button @click="feedback = false" type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
        <button @click="login" v-if="!sending" type="button" class="btn btn-sm btn-primary">Σύνδεση</button>
        <button v-if="sending" class="btn btn-sm btn-outline-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      feedback: '',
      sending: false,
      name: "",
      nameValidator: "",
      passValidator: "",
      password: ""
    }
  },
  methods: {
    checkName() {
      try {
        if (this.name === "") {
          this.nameValidator = true
          this.$refs["adminName"].classList.add("is-invalid")
          this.$refs["adminName"].classList.remove("is-valid")
        } else {
          this.nameValidator = false
          this.$refs["adminName"].classList.remove("is-invalid")
          this.$refs["adminName"].classList.add("is-valid")
        }
      } catch (error) {
        this.$store.dispatch("LOG_EVENT", (error))
      }
    },
    checkPassword() {
      try {
        if (this.password === "") {
          this.passValidator = true
          this.$refs["adminPass"].classList.add("is-invalid")
          this.$refs["adminPass"].classList.remove("is-valid")
        } else {
          this.passValidator = false
          this.$refs["adminPass"].classList.remove("is-invalid")
          this.$refs["adminPass"].classList.add("is-valid")
        }
      } catch (error) {
        this.$store.dispatch("LOG_EVENT", (error))
      }
    },
    login() {
      try {
        this.checkName()
        this.checkPassword()
        if (this.passValidator && this.nameValidator) return false
        if (!this.nameValidator && !this.passValidator) {
          this.feedback = ""
          this.sending = true
          axios.post("/api/adminLogin", { password: this.password.trim(), name: this.name.trim() }).then(({data}) => {
            this.feedback = data
            this.sending = false
            if (!data) {
              this.$refs["adminName"].classList.remove("is-invalid")
              this.$refs["adminName"].classList.remove("is-valid")
              this.$refs["adminPass"].classList.remove("is-invalid")
              this.$refs["adminPass"].classList.remove("is-valid")
              this.password = ""
              this.name = ""
              this.$store.commit("SET_AUTH", false)
              this.feedback = "Λάθος στοιχεία"
            } else {
              this.$refs["adminName"].classList.remove("is-invalid")
              this.$refs["adminName"].classList.remove("is-valid")
              this.$refs["adminPass"].classList.remove("is-invalid")
              this.$refs["adminPass"].classList.remove("is-valid")
              localStorage.setItem("token", data)
              this.feedback = "Σύνδεση επιτυχής"
              this.$store.commit("SET_AUTH", true)
              this.name = ""
              this.password = ''
            }
          }).catch(error => this.$store.commit("LOG_EVENT", error))
        } else {
          this.$store.commit("SET_AUTH", false)
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>

<style scoped>
</style>
