<template>
    <div class="row p-0 m-0">
      <h3 class="mb-4">Εκδηλώσεις <span v-if="auth" class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#eventModal">add event</span> </h3>
      <!-- placeholder when no events -->
      <div v-if="events.length == 0" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 m-0 mb-4" style="height: 500px !important;">
        <div class="card shadow h-100">
          <img src="https://i.imgur.com/iQiHjxr.jpg" class="card-img-top" alt="event-image" height="220" style="object-fit: cover;">
          <div class="card-body">
            <h5 class="card-title">0 Εκδηλώσεις</h5>
            <div class="card-text mt-4">
              Εδώ θα βρείτε όλες τις ανερχόμενες εκδηλώσεις της κοινότητας.
            </div>
          </div>
        </div>
      </div>

      <!-- dynamic  -->
      <EventPost v-for="event in rendered" :key="event._id" :event="event" />
      <EventForm />
      <nav aria-label="...">
        <ul class="pagination float-end">
          <li v-if="this.events.length > 2" @click="setList(1)" class="page-item active" id="eventFirst"><a class="page-link" type="button">1</a></li>
          <li v-if="this.events.length > 2" @click="setList(2)" class="page-item" id="eventSecond"><a class="page-link" type="button">2</a></li>
          <li v-if="this.events.length > 4" @click="setList(3)" class="page-item" id="eventThird"><a class="page-link" type="button">3</a></li>
        </ul>
      </nav>
    </div>
</template>

<script>
import axios from 'axios'
import EventForm from '../components/EventForm'
import EventPost from '../components/EventPost'
export default {
  computed: {
    auth() {
      return this.$store.state.auth
    }
  },
  components: {
    EventPost,
    EventForm,
  },
  data() {
    return {
      events: [],
      rendered: []
    }
  },
  methods: {
    setList(num) {
      try {
        var current, eventFirst, eventSecond, eventThird
        if (this.events.length > 4) {
          eventFirst = document.getElementById("eventFirst")
          eventSecond = document.getElementById("eventSecond")
          eventThird = document.getElementById("eventThird")
          if (num === 2) {
            eventFirst.classList.remove("active")
            eventThird.classList.remove("active")
            eventSecond.classList.add("active")
            current = this.events
            this.rendered = []
            this.rendered = current.slice(2,4)
          } else if (num === 1) {
            eventSecond.classList.remove("active")
            eventThird.classList.remove("active")
            eventFirst.classList.add("active")
            current = this.events
            this.rendered = []
            this.rendered = current.slice(0,2)
          } else if (num === 3) {
            eventSecond.classList.remove("active")
            eventThird.classList.add("active")
            eventFirst.classList.remove("active")
            current = this.events
            this.rendered = []
            this.rendered = current.slice(4,6)
          }
        } else if (this.events.length > 2 && this.events.length <= 4) {
          eventFirst = document.getElementById("eventFirst")
          eventSecond = document.getElementById("eventSecond")
          if (num === 2) {
            eventFirst.classList.remove("active")
            eventSecond.classList.add("active")
            current = this.events
            this.rendered = []
            this.rendered = current.slice(2,4)
          } else if (num === 1) {
            eventSecond.classList.remove("active")
            eventFirst.classList.add("active")
            current = this.events
            this.rendered = []
            this.rendered = current.slice(0,2)
          } else if (num === 3) {
            eventSecond.classList.remove("active")
            eventFirst.classList.remove("active")
            current = this.events
            this.rendered = []
            this.rendered = current.slice(4,6)
          }
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
      }
  },
  mounted() {
    try {
      axios.get("/api/fetchEvents").then((res) => {
        if (!res.data) return false
        res.data.forEach((ev) => {
          this.events.push(ev)
        })
        this.rendered = this.events.slice(0,2)
      }).catch(error => this.$store.commit("LOG_EVENT", error))
    } catch (error) {
      this.$store.commit("LOG_EVENT", (error))
    }
  }
}
</script>
