<template>
  <div>
    <div class="row m-0 me-2 ms-2 mb-5">
      <div class="container p-0 m-0">
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>COVID-19 </strong> Για πληροφορίες σχετικά με την πανδημία επισκεφθείτε την ιστοσελίδα του <a href="https://www.bag.admin.ch/bag/en/home.html">BAG</a>.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div><br>
          Η υποσέλιδα αυτή είναι <strong>υπό κατασκευή</strong>. Για πληροφορίες, επικοινωνήστε μαζί μας.
          <br><br>
          <button class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#contactModal">Επικοινωνία</button>
      </div>
      </div>
  </div>
</template>

<script>
export default {
}
</script>
