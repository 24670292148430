<template>
  <div id="app" class="bg-light">
    <div class="m-0 p-0" id="wrapper">
      <Navigation />
      <div class="container p-0 mt-5">
        <div class="row p-0 m-0">
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 p-0 m-0">
            <router-view></router-view>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 p-0 m-0 ps-xl-4 ps-lg-4 ps-md-3 ps-sm-0">
            <Links />
          </div>
        </div>
      </div>
      <AdminEntry />
      <ContactForm />
      <MembershipForm />
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Navigation from './components/Navigation'
import Links from './components/Links'
import Footer from './components/Footer'
import ContactForm from './components/ContactForm'
import AdminEntry from './components/AdminEntry'
import MembershipForm from './components/MembershipForm'
export default {
  mounted() {
      axios.post("/api/connection", { token: localStorage.getItem("token") }).then(() => {
      }).catch(error => this.$store.commit("LOG_EVENT", error))
      this.$store.dispatch("FETCH_NEWS")
      this.$store.dispatch("FETCH_LINKS")
      this.$store.dispatch("FETCH_EVENTS")
  },
  components: {
    Navigation,
    Links,
    ContactForm,
    Footer,
    AdminEntry,
    MembershipForm,
  }
}
</script>

<style>
body, html, #app, #wrapper {
  min-height: calc(100vh - 50px) !important;
}
</style>
