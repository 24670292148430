<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-0 mb-4" style="height: 400px !important;">
    <div class="card shadow h-100">
      <img v-if="link.image" :src="link.image" class="card-img-top" alt="link-image" height="150" style="object-fit: cover;">
      <div class="card-body">
        <h5 class="card-title">{{link.name}}</h5>
        <div class="card-text mt-4">
          {{plainText.substring(0, 100)}} <span @click="$root.$emit('scrollTop')"><router-link :to="'/links/'+link._id">...περισσότερα</router-link></span>
        </div>
      </div>
      <div class="card-footer bg-white border-white">
        <span @click="like" class="btn btn-sm border float-start">&#128077; {{link.likes}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import removeMD from 'remove-markdown'
import axios from 'axios'
export default {
  data() {
    return {
      plainText: removeMD(this.link.information)
    }
  },
  props: {
    link: Object,
  },
  mounted() {
    try {
      window.scrollTo(0, 0)
    } catch {
      console.log("Could not scroll up page.")
    }

    this.$root.$on("increment_link_likes", (id) => {
      if (this.link._id === id) {
        this.link.likes++
      }
    })
  },
  methods: {
    setCurrentLink() {
      this.$store.commit("SET_CURRENT_LINK", { current: this.link })
    },
    like() {
      try {
        if (localStorage.getItem(`liked-${this.link._id}`)) return false
        localStorage.setItem("liked-"+this.link._id, "true")
        this.$store.commit("INCREMENT_LIKES", this.link._id)
        this.link.likes++
        axios.post("/api/likeLink", { linkId: this.link._id }).catch(error => this.$store.commit("LOG_EVENT", error))
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  }
}
</script>
