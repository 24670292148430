<template>
    <div class="row p-0 m-0">
      <h3 class="mb-4">Νέα <span v-if="auth" class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#newsModal"> add news</span></h3>
      <NewsPost :post="post" v-for="post in rendered" :key="post._id"/>
      <NewsForm />
      <nav aria-label="...">
        <ul class="pagination float-end">
          <li v-if="this.newsPosts.length > 2" @click="setList(1)" class="page-item active" id="first"><a class="page-link" type="button">1</a></li>
          <li v-if="this.newsPosts.length > 2" @click="setList(2)" class="page-item" id="second"><a class="page-link" type="button">2</a></li>
          <li v-if="this.newsPosts.length > 4" @click="setList(3)" class="page-item" id="third"><a class="page-link" type="button">3</a></li>
        </ul>
      </nav>
    </div>
</template>

<script>
import axios from 'axios'
import NewsForm from '../components/NewsForm'
import NewsPost from '../components/NewsPost'
export default {
  computed: {
    auth() {
      return this.$store.state.auth
    }
  },
  components: {
    NewsForm,
    NewsPost,
  },
  data() {
    return {
      newsPosts: [],
      rendered: [],
    }
  },
  methods: {
    setList(num) {
      try {
        var current, first, second, third
        if (this.newsPosts.length > 4) {
          first = document.getElementById("first")
          second = document.getElementById("second")
          third = document.getElementById("third")
          if (num === 2) {
            first.classList.remove("active")
            third.classList.remove("active")
            second.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(2,4)
          } else if (num === 1) {
            second.classList.remove("active")
            third.classList.remove("active")
            first.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(0,2)
          } else if (num === 3) {
            second.classList.remove("active")
            third.classList.add("active")
            first.classList.remove("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(4,6)
          }
        } else if (this.newsPosts.length > 2 && this.newsPosts.length <= 4) {
          first = document.getElementById("first")
          second = document.getElementById("second")
          if (num === 2) {
            first.classList.remove("active")
            second.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(2,4)
          } else if (num === 1) {
            second.classList.remove("active")
            first.classList.add("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(0,2)
          } else if (num === 3) {
            second.classList.remove("active")
            first.classList.remove("active")
            current = this.newsPosts
            this.rendered = []
            this.rendered = current.slice(4,6)
          }
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  },
  mounted() {
    try {
      axios.get("api/fetchNewsPosts").then((res) => {
        if (!res.data) return false
        res.data.forEach((post) => {
          this.newsPosts.push(post)
        })
        this.rendered = this.newsPosts.slice(0,2)
      }).catch(error => this.$store.commit("LOG_EVENT", error))
    } catch (error) {
      this.$store.commit("LOG_EVENT", (error))
    }
  }
}
</script>
