import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Topics from '../views/Topics'
import School from '../views/School'
import Church from '../views/Church'
import NewsPostFull from '../components/NewsPostFull'
import LinkFull from '../components/LinkFull'
import EventFull from '../components/EventFull'

Vue.use(VueRouter)

const routes = [
  { path: "/", component: Home },
  { path: "/topics", component: Topics },
  { path: "/school", component: School },
  { path: "/church", component: Church },
  { path: "/news/:id", component: NewsPostFull },
  { path: "/links/:id", component: LinkFull },
  { path: "/events/:id", component: EventFull }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
