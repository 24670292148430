<template>
  <div class="row m-0 me-2 ms-2 mb-5">
    <div class="accordion accordion-flush p-0" id="accordionFlushExample">


    <div class="accordion-item" id="infoGr">
    <h2 class="accordion-header" id="flush-headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
        Πληροφορίες εγγραφής
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body p-0 m-0 mb-4">
        <MarkdownItVue :content="schoolRegGr" class="mt-3"/>
      </div>
    </div>
  </div>

  <div class="accordion-item" id="infoDe">
    <h2 class="accordion-header" id="flush-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
        Allgemeine Anmeldungsinformationen
      </button>
    </h2>
    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body p-0 m-0 mb-4">
        <MarkdownItVue :content="schoolRegDe" class="mt-3"/>
      </div>
    </div>
  </div>


  <div class="accordion-item" id="schoolLocation">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        Τοποθεσία
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body p-0 m-0 mb-4">
        <LocalMap :location="{center: [7.390652524702995, 46.947438437596134], where: [7.3914411483390285, 46.94754492635872], legend: {name: 'Tscharnergut I - Schulhaus', strasse: 'Fellerstrasse 18', plz: '3027 Bern'}}" />
      </div>
    </div>
  </div>
  <div class="accordion-item" id="teacherBios">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        Εκπαιδευτικοί
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="row p-0 m-0 mb-5 mt-5">
          <Teacher v-for="teacher in teachers" :key="teacher.name" :teacher='teacher' />
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>

import MarkdownItVue from 'markdown-it-vue'
import { teachers } from './teachers'
import LocalMap from '../components/LocalMap'
import Teacher from '../components/Teacher'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import schoolRegGr from "raw-loader!../assets/schoolRegGr.md";
import schoolRegDe from "raw-loader!../assets/schoolRegDe.md";
export default {
  components: {
    LocalMap,
    Teacher,
    MarkdownItVue
  },
  data() {
    return {
      teachers: teachers,
      schoolRegGr: schoolRegGr,
      schoolRegDe: schoolRegDe
    }
  }
}
</script>
