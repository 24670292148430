<template>
<div class="modal fade" id="contactModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header border-white">
        <h5 class="modal-title" id="exampleModalLabel">Επικοινωνία</h5>
        <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row g-2">
          <p>Εάν Θέλετε να επισυνάψετε αρχεία, στείλτε απευθείας Email στο <a href="mailto:griechischegemeindebern@gmail.com">griechischegemeindebern@gmail.com</a>.</p>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="form-floating mb-2">
              <input @input="checkName" v-model="name" type="text" ref="contactName" class="form-control" id="floatingInput1" placeholder="name@example.com">
              <label for="floatingInput1">Oνοματεπώνυμο</label>
              <div v-if="nameValidator" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
           </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 ps-lg-2">
            <div class="form-floating mb-3">
               <input @input="checkEmail" v-model="email" ref="contactEmail" type="email" class="form-control" id="floatingInput2" placeholder="name@example.com">
               <label for="floatingInput2">Email</label>
               <div v-if="emailValidator" class="form-text text-danger">Εισάγεται ένα έγκυρο Email</div>
            </div>
          </div>
        </div>
        <div class="form-floating mb-3">
          <textarea @input="checkMessage" v-model="message" class="form-control" ref="contactMessage" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 200px;"></textarea>
          <label for="floatingTextarea2">Μήνυμα</label>
            <div v-if="messageValidator" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
        </div>
        <div class="form-floating mb-3">
          <select @change="checkRelevance" class="form-select" v-model="relevance" ref="contactRelevance" id="floatingSelect" aria-label="Floating label select example">
            <option value="general">Κοινότητα</option>
            <option value="school">Σχολείο</option>
            <option value="church">Εκκλησία</option>
            <option value="advertisment">Διαφήμιση</option>
           </select>
           <label for="floatingSelect">Σχετικό με</label>
           <div class="form-text">
             Η επιλογή θέματος διευκολύνει την επεξεργασία του αιτήματος σας
           </div>
           <div v-if="relevanceValidator" class="form-text text-danger">Η επιλογή θέματος είναι υποχρεωτική</div>
          </div>
        <div v-if="feedback" class="alert alert-primary alert-dismissible fade show" role="alert">
          {{feedback}}
          <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="feedback = false" type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
        <button @click="contact" type="button" v-if="!sending" class="btn btn-sm btn-primary">Αποστολή</button>
        <button v-if="sending" class="btn btn-sm btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Αποστολή...
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      name: "",
      nameValidator: "",
      email: '',
      emailValidator: "",
      message: "",
      messageValidator: "",
      relevance: "",
      relevanceValidator: "",
      sending: false,
      feedback: ''
    }
  },
  methods: {
      checkRelevance() {
        try {
          if (this.relevance === "") {
            this.relevanceValidator = true
            this.$refs["contactRelevance"].classList.add("is-invalid")
            this.$refs["contactRelevance"].classList.remove("is-valid")
          } else {
            this.relevanceValidator = false
            this.$refs["contactRelevance"].classList.remove("is-invalid")
            this.$refs["contactRelevance"].classList.add("is-valid")
          }
        } catch (error) {
          this.$store.commit("LOG_EVENT", (error))
        }
      },
      checkName() {
        try {
          if (this.name === "") {
            this.nameValidator = true
            this.$refs["contactName"].classList.add("is-invalid")
            this.$refs["contactName"].classList.remove("is-valid")
          } else {
            this.nameValidator = false
            this.$refs["contactName"].classList.remove("is-invalid")
            this.$refs["contactName"].classList.add("is-valid")
          }
        } catch (error) {
          this.$store.commit("LOG_EVENT", (error))
        }
      },
      checkEmail() {
        try {
          if (this.email === "" || !this.validateEmail(this.email)) {
            this.emailValidator = true
            this.$refs["contactEmail"].classList.add("is-invalid")
            this.$refs["contactEmail"].classList.remove("is-valid")
          } else {
            this.emailValidator = false
            this.$refs["contactEmail"].classList.remove("is-invalid")
            this.$refs["contactEmail"].classList.add("is-valid")
          }
        } catch (error) {
          this.$store.commit("LOG_EVENT", (error))
        }
      },
      checkMessage() {
        try {
          if (this.message === "") {
            this.messageValidator = true
            this.$refs["contactMessage"].classList.add("is-invalid")
            this.$refs["contactMessage"].classList.remove("is-valid")
          } else {
            this.messageValidator = false
            this.$refs["contactMessage"].classList.remove("is-invalid")
            this.$refs["contactMessage"].classList.add("is-valid")
          }
        } catch (error) {
          this.$store.commit("LOG_EVENT", (error))
        }
      },
    contact() {
      try {
        this.checkMessage()
        this.checkEmail()
        this.checkMessage()
        this.checkRelevance()
        if (!this.nameValidator && !this.emailValidator && !this.messageValidator && !this.relevanceValidator) {
          this.feedback = false
          this.sending = true
          axios.post("/api/contact", {
            name: this.name.trim(),
            message: this.message.trim(),
            email: this.email.trim(),
            relevance: this.relevance
          }).then(({data}) => {
            this.sending = false
            this.feedback = data
            this.relevance = ""
            this.relevanceValidator = ""
            this.name = ""
            this.nameValidator = ""
            this.email = ""
            this.emailValidator = ""
            this.message = ""
            this.messageValidator = ""
            this.$refs["contactName"].classList.remove("is-invalid")
            this.$refs["contactName"].classList.remove("is-valid")
            this.$refs["contactEmail"].classList.remove("is-invalid")
            this.$refs["contactEmail"].classList.remove("is-valid")
            this.$refs["contactMessage"].classList.remove("is-invalid")
            this.$refs["contactMessage"].classList.remove("is-valid")
            this.$refs["contactRelevance"].classList.remove("is-invalid")
            this.$refs["contactRelevance"].classList.remove("is-valid")
          }).catch(error => this.$store.commit("LOG_EVENT", error))
        } else {
          this.checkName()
          this.checkEmail()
          this.checkMessage()
          this.checkRelevance()
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
  }
}
</script>
