<template>
  <tr>
    <td>{{entry.date}}</td>
    <td>{{entry.message.substring(0, 50)}}</td>
    <td>{{entry.stack.substring(0,50)}}</td>
    <td>{{entry.location}}</td>
  </tr>
</template>

<script>
export default {
  props: {
    entry: Object,
  },
}
</script>
