<template>
  <div class="row p-0 m-0">
    <h3 class="mb-4">Links <span data-bs-toggle="modal" data-bs-target="#contactModal" class="btn btn-sm btn-outline-success">Διαφημιστείτε</span> <span v-if="auth" class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#adModal"> new add</span></h3>
    <Link v-for="link in links" :key="link._id" :link="link"/>
    <AdForm />
  </div>
</template>

<script>
import axios from 'axios'
import AdForm from '../components/AdForm'
import Link from '../components/Link'
export default {
  computed: {
    auth() {
      return this.$store.state.auth
    }
  },
  components: {
    AdForm,
    Link
  },
  data() {
    return {
      links: []
    }
  },
  methods: {
    fisherYates(array) {
      try {
        let i = array.length;
        while (i--) {
          const ri = Math.floor(Math.random() * (i + 1));
          [array[i], array[ri]] = [array[ri], array[i]];
        }
        return array;
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  },
  mounted() {
    try {
      axios.get("/api/fetchAds").then((res) => {
        if (!res.data) return false
        res.data.forEach((ad) => {
          this.links.push(ad)
        })
        this.links = this.fisherYates(this.links)
      }).catch(error => this.$store.commit("LOG_EVENT", error))
    } catch (error) {
      this.$store.commit("LOG_EVENT", (error))
    }
  }
}
</script>
