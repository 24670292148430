<template>
  <div class="container p-0 mt-4 m-0">
    <div class="row p-0 m-0">
      <vl-map class="m-0 p-0" :load-tiles-while-animating="true" :load-tiles-while-interacting="true" data-projection="EPSG:4326" style="height: 400px">
    <vl-view :zoom.sync="zoom" :center.sync="location.center"></vl-view>
    <vl-layer-tile id="osm">
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>

    <vl-overlay id="overlay" :position="location.where">
      <template>
        <div class="overlay-content">
          <div class="alert alert-light shadow">
        <p style="font-weight:bold;">{{location.legend.name}}</p>
        <p class="m-0">{{location.legend.strasse}}</p>
        <p>{{location.legend.plz}}</p>
      </div>
        </div>
      </template>
    </vl-overlay>
  </vl-map>
    </div>
</div>
</template>

<script>
export default {
  props: {
    location: Object
  },
  data() {
    return {
      zoom: 16,
    }
  }
}
</script>
