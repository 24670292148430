<template>
    <tr>
        <td>
            {{  post.date }}
        </td>
        <td>{{  post.time }}</td>
        <td>{{  post.location }}</td>
        <td v-if="auth"><button @click="remove" class="btn btn-danger btn-sm">delete</button></td>
    </tr>
</template>

<script>
import axios from 'axios'

export default {
    computed: {
        auth () {
        return this.$store.state.auth;
        }
  },
    props: {
        post: Object
    },
    methods: {
        async remove() {
            const id = this.post._id;
            await axios.delete(`/api/church-event/${id}`);
            window.location.reload();
    }
    }
}
</script>

<style>

</style>