<template>
<div class="modal fade" tabindex="-1" id="membershipForm">
  <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header border-white">
        <h5 class="modal-title">Αίτηση εγγραφής στην Κοινότητα</h5>
        <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
                <div class="row g-2">
                  <h6 class="form-lable">Στοιχεία αιτούντος</h6>
                  <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
                    <div class="form-floating">
                      <input @input="check('name')" v-model="user.name" type="text" ref="name" class="form-control" id="float1" placeholder="name@example.com">
                      <label for="float1">Ονοματεπώνυμο</label>
                      <div v-if="userValidators.name" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3 ps-lg-2">
                    <div class="form-floating">
                      <select @change="check('anrede')" class="form-select" v-model="user.anrede" ref="anrede" id="floatingSelect" aria-label="Floating label select example">
                        <option value="Κύριος">Κύριος</option>
                        <option value="Κυρία">Κυρία</option>
                       </select>
                       <label for="floatingSelect">Προσφώνηση</label>
                       <div v-if="userValidators.anrede" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                      </div>
                  </div>
                </div>
                <div class="row g-2">
                  <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
                    <div class="form-floating">
                      <input @input="check('email')" v-model="user.email" type="email" ref="email" class="form-control" id="float3" placeholder="name@example.com">
                      <label for="float3">Email</label>
                      <div v-if="userValidators.email" class="form-text text-danger">Εισάγεται ένα έγκυρο Email</div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 mb-3 ps-lg-2">
                    <div class="form-floating">
                      <input @input="check('phone')" v-model="user.phone" type="number" ref="phone" class="form-control" id="float4" placeholder="name@example.com">
                      <label for="float4">Τηλέφωνο</label>
                      <div v-if="userValidators.phone" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                </div>
                <div class="row g-2 mb-2">


                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <div class="form-floating">
                      <input @input="check('address')" v-model="user.address" type="text" ref="address" class="form-control" id="float5" placeholder="name@example.com">
                      <label for="float5">Οδός</label>
                      <div v-if="userValidators.address" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2 ps-lg-2">
                    <div class="form-floating">
                      <input @input="check('number')" v-model="user.number" type="number" ref="number" class="form-control" id="float6" placeholder="name@example.com">
                      <label for="float6">Aριθμός</label>
                      <div v-if="userValidators.number" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                  <!-- new -->
                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <div class="form-floating">
                      <input @input="check('city')" v-model="user.city" type="text" ref="city" class="form-control" id="float99" placeholder="name@example.com">
                      <label for="float99">Πόλη/Μέρος</label>
                      <div v-if="userValidators.city" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>

                  <!-- new -->

                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2 ps-lg-2">
                    <div class="form-floating">
                      <input @input="check('plz')" v-model="user.plz" type="number" ref="plz" class="form-control" id="float7" placeholder="name@example.com">
                      <label for="float7">Τ.Κ.</label>
                      <div v-if="userValidators.plz" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>


                </div>
                <div class="row g-2">
                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                    <div class="form-floating">
                      <input  v-model="user.job" type="text" ref="job" class="form-control" id="float11" placeholder="name@example.com">
                      <label for="float11">Επάγγελμα</label>
                      <div v-if="userValidators.job" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 mb-3 ps-lg-2">
                    <div class="form-floating">
                      <input v-model="user.boss" type="text" ref="boss" class="form-control" id="float8" placeholder="name@example.com">
                      <label for="float8">Εργοδότης</label>
                      <div v-if="userValidators.boss" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                </div>
                <div class="row g-2">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Ημερομηνία Γεννήσεως</label>
                      <input @input="check('birthday')" v-model="user.birthday" type="date" ref="birthday" class="form-control form-control-lg" id="float2"/>
                      <div v-if="userValidators.birthday" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 mb-1 ps-lg-2">
                    <div class="mb-3">
                      <label class="form-label" for="float9">Ημερομηνία εγκατάστασης στην Ελβετία</label>
                      <input @input="check('date')" v-model="user.date" type="date" ref="date" class="form-control form-control-lg" id="float9" placeholder="name@example.com">
                      <div v-if="userValidators.date" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-check form-check-inline">
                  <input v-model="user.married" type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Έγγαμος</label>
                </div>
                <div class="mb-3 form-check form-check-inline">
                  <input v-model="user.hasKids" type="checkbox" class="form-check-input" id="exampleCheck2">
                  <label class="form-check-label" for="exampleCheck2">Παιδιά</label>
                </div>
                <div class="mb-3 form-check form-check-inline">
                  <input v-model="user.addPartner" type="checkbox" class="form-check-input" id="exampleCheck3">
                  <label class="form-check-label" for="exampleCheck3">Εγγραφή συντρόφου</label>
                </div>

                <!-- if partner -->
                <div v-if="user.addPartner" class="m-0 p-0 mt-3">
                  <div class="row g-2">
                    <h6 class="form-lable">Στοιχεία συντρόφου</h6>
                    <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
                      <div class="form-floating">
                        <input @input="checkPartner('name')" v-model="partner.name" type="text" ref="namePartner" class="form-control" id="float12" placeholder="name@example.com">
                        <label for="float12">Ονοματεπώνυμο</label>
                        <div v-if="partnerValidators.name" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-3 ps-lg-2">
                      <div class="form-floating">
                        <select @change="checkPartner('anrede')" class="form-select" v-model="partner.anrede" ref="anredePartner" id="floatingSelect" aria-label="Floating label select example">
                          <option value="Κύριος">Κύριος</option>
                          <option value="Κυρία">Κυρία</option>
                         </select>
                         <label for="floatingSelect">Προσφώνηση</label>
                         <div v-if="partnerValidators.anrede" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                        </div>
                    </div>
                  </div>
                  <div class="row g-2">
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                      <div class="form-floating">
                        <input  v-model="partner.job" type="text" ref="jobPartner" class="form-control" id="float13" placeholder="name@example.com">
                        <label for="float13">Επάγγελμα</label>
                        <div v-if="partnerValidators.job" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-3 ps-lg-2">
                      <div class="form-floating">
                        <input v-model="partner.boss" type="text" ref="bossPartner" class="form-control" placeholder="name@example.com">
                        <label for="float14">Εργοδότης</label>
                        <div v-if="partnerValidators.boss" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-2">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Ημερομηνία Γεννήσεως</label>
                        <input @input="checkPartner('birthday')" v-model="partner.birthday" type="date" ref="birthdayPartner" class="form-control"/>
                        <div v-if="partnerValidators.birthday" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 mb-1 ps-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="float9">Ημερομηνία εγκατάστασης στην Ελβετία</label>
                        <input @input="checkPartner('date')" v-model="partner.date" type="date" ref="datePartner" class="form-control" placeholder="name@example.com">
                        <div v-if="partnerValidators.date" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="user.hasKids" class="mb-5">
                  <div class="form-floating">
                    <input v-model="user.kids" type="text" ref="kids" class="form-control" id="float15" placeholder="name@example.com">
                    <label for="float99">Παιδιά</label>
                    <div class="form-text text-muted">Σε περίπτωση περισσότερων παιδιών, καταχωρίστε κάθε παιδί με την ημερομηνία γέννησης του, χωρισμένα με κόμμα.</div>
                  </div>
                </div>





        <div class="mb-3 mt-3">
          <label class="mb-3" style="font-weight: bold;">Όροι συμμετοχής</label>
          <ul>
            <li>Ο αιτών πρέπει να είναι τουλάχιστον 18 χρονών.</li>
            <li>Να εχει ελληνική υπηκοότητα.</li>
            <li>Να μένει στη Βέρνη η κοντά σε αυτήν.</li>
            <li>Μονά μέλη συνεισφέρουν την ετήσια συνδρομή των <span class="badge bg-light text-dark">60 CHF</span>.</li>
            <li>Ζευγάρια συνεισφέρουν την ετήσια συνδρομή των <span class="badge bg-light text-dark">100 CHF</span>.</li>
            <li>Η αίτηση έγγραφης γίνεται αποδέκτη μόνο μετά τη μεταβίβασή της συνδρομής. Μέτα την αποστολή της αίτησης σας, θα λάβετε Email με τα απαραίτητα στοιχεία.</li>
          </ul>
        </div>
        <div class="mb-3 form-check">
          <input @change="checkAccept" value="false" v-model="accept" type="checkbox" class="form-check-input">
          <label class="form-check-label font-weight-bold">
            Δηλώνω πως έχω διαβάσει και αποδεχτεί τους όρους συμμετοχής. Με την αποστολή αυτής της φόρμας, ζήτω να γίνω τακτικό μέλος της Ελληνικής κοινότητάς Βέρνης.
          </label>
          <div v-if="acceptValidator" class="form-text text-danger">Η αποδοχή των όρων εγγραφής είναι υποχρεωτική</div>
        </div>


              <div v-if="this.res" class="alert alert-primary alert-dismissible fade show" role="alert">
                Λάβαμε την αίτηση σας με επιτυχία. Θα επικοινωνήσουμε μαζί σας συντομα.
                <button @click="res = null" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
              <div v-else-if="this.res === false" class="alert alert-warning alert-dismissible fade show" role="alert">
                Δεν μπορέσαμε να λάβουμε την αίτηση σας. Παρακαλώ προσπαθήστε ξανά.
                <button @click="res = null" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
              <div v-if="this.feedback === true" class="alert alert-warning alert-dismissible fade show" role="alert">
                Παρακαλώ συμπληρώστε όλα τα πεδία με *, εισάγετε ένα έγκυρο Email και αποδεχτείτε τους όρους συμμετοχής.
                <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button @click="res = null, feedback = false" type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
              <button v-if="!sending" @click="send" type="submit" class="btn btn-sm btn-primary">Αποστολή</button>
              <button v-else-if="sending" class="btn btn-sm btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Αποστολή..
              </button>
            </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      res: '',
      feedback: '',
      sending: '',
      userValidators: {
        name: '',
        anrede: "",
        birthday: '',
        email: '',
        phone: '',
        address: '',
        number: '',
        plz: '',
        city: '',
        date: '',
      },
      partnerValidators: {
        name: '',
        anrede: '',
        birthday: '',
        date: '',
      },
      user: {
        addPartner: '',
        name: '',
        anrede: '',
        birthday: '',
        email: '',
        phone: '',
        address: '',
        number: '',
        plz: '',
        city: '',
        job: '',
        boss: '',
        date: '',
        married: '',
        hasKids: '',
        kids: ''
      },
      partner: {
        name: '',
        anrede: '',
        birthday: '',
        job: '',
        boss: '',
        date: '',
      },
      accept: '',
      acceptValidator: '',
    }
  },
  methods: {
    checkAccept() {
      try {
        if (!this.accept) {
          this.acceptValidator = true
          return false
        } else {
          this.acceptValidator = false
          return true
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    check(prop) {
      try {
        var state = true
        if (eval(`this.user.${prop}`) === "") {
          new Function(eval(`this.userValidators.${prop} = true`))
          this.$refs[prop].classList.add("is-invalid")
          this.$refs[prop].classList.remove("is-valid")
          state = false
          return state
        } else {
          if (this.$refs[prop].type === "email") {
            if (!this.validateEmail(this.user.email)) {
              new Function(eval(`this.userValidators.${prop} = true`))
              this.$refs[prop].classList.add("is-invalid")
              this.$refs[prop].classList.remove("is-valid")
              state = false
              return state
            } else {
              new Function(eval(`this.userValidators.${prop} = false`))
              this.$refs[prop].classList.remove("is-invalid")
              this.$refs[prop].classList.add("is-valid")
              state = true
              return state
            }
          }
          new Function(eval(`this.userValidators.${prop} = false`))
          this.$refs[prop].classList.remove("is-invalid")
          this.$refs[prop].classList.add("is-valid")
          state = true
          return state
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    checkPartner(prop) {
      try {
        var state = true
        if (eval(`this.partner.${prop}`) === "") {
          new Function(eval(`this.partnerValidators.${prop} = true`))
          this.$refs[prop+'Partner'].classList.add("is-invalid")
          this.$refs[prop+'Partner'].classList.remove("is-valid")
          state = false
          return false
        } else {
          new Function(eval(`this.partnerValidators.${prop} = false`))
          this.$refs[prop+'Partner'].classList.remove("is-invalid")
          this.$refs[prop+'Partner'].classList.add("is-valid")
          state = true
          return state
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    send() {
      try {
        var sendable = true
        for (var prop in this.userValidators) {
          var check = this.check(prop)
          if (check === false) sendable = false
        }
        if (this.user.addPartner) {
          for (var attr in this.partnerValidators) {
            check = this.checkPartner(attr)
            if (check === false) sendable = false
          }
        }
        if (!this.checkAccept()) sendable = false
        if (sendable) {
          this.sending = true
          this.feedback = false
          axios.post("/api/application", { user: this.user, partner: this.partner }).then((res) => {
            this.sending = false
            res.data === true ? this.res = true : this.res = false
            if (this.user.addPartner) {
              for (var d in this.partnerValidators) {
                this.$refs[d+'Partner'].classList.remove("is-invalid")
                this.$refs[d+'Partner'].classList.remove("is-valid")
                this.partnerValidators[d] = ''
              }
            }
            for (var a in this.user) {
              this.user[a] = ''
            }
            for (var b in this.partner) {
              this.partner[b] = ''
            }
            for (var c in this.userValidators) {
              this.$refs[c].classList.remove("is-invalid")
              this.$refs[c].classList.remove("is-valid")
              this.userValidators[c] = ''
            }
            this.accept = ''
            this.acceptValidator = ''
          }).catch(error => this.$store.commit("LOG_EVENT", error))
        }
      } catch (error) {
        this.res = false
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
  }
}
</script>