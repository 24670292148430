<template>
<div class="modal fade" tabindex="-1" id="schoolForm">
  <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header border-white">
        <h5 class="modal-title">Αίτηση εγγραφής στο σχολείο</h5>
        <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div class="row g-2">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
            <div class="form-floating">
              <input @input="check('nameChild')" v-model="application.nameChild" type="text" ref="nameChild" class="form-control" id="float101" placeholder="name@example.com">
              <label for="float101">Ονοματεπώνυμο μαθήτριας/μαθητή</label>
              <div v-if="applicationValidators.nameChild" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
              <div class="form-text text-muted">Με λατινικούς χαρακτήρες</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3 ps-lg-2">
            <div class="form-floating">
              <input @input="check('currentClass')" v-model="application.currentClass" type="text" ref="currentClass" class="form-control" id="float102" placeholder="name@example.com">
              <label for="float102">Τάξη στο ελβετικό σχολείο</label>
              <div v-if="applicationValidators.currentClass" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Ημερομηνία Γεννήσεως</label>
          <input @input="check('birthday')" v-model="application.birthday" type="date" ref="birthday" class="form-control form-control-lg"/>
          <div v-if="applicationValidators.birthday" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
        </div>
        <div class="row g-2">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
            <div class="form-floating">
              <input @input="check('nameFather')" v-model="application.nameFather" type="text" ref="nameFather" class="form-control" id="float103" placeholder="name@example.com">
              <label for="float103">Ονοματεπώνυμο πατέρα</label>
              <div v-if="applicationValidators.nameFather" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3 ps-lg-2">
            <div class="form-floating">
              <input @input="check('nameMother')" v-model="application.nameMother" type="text" ref="nameMother" class="form-control" id="float104" placeholder="name@example.com">
              <label for="float104">Ονοματεπώνυμο μητέρας</label>
              <div v-if="applicationValidators.nameMother" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-sm-12 col-md-12 col-lg-8 mb-2">
            <div class="form-floating">
              <input @input="check('email')" v-model="application.email" type="email" ref="email" class="form-control" id="float105" placeholder="name@example.com">
              <label for="float105">Email</label>
              <div class="form-text text-muted">Μπορείτε να καταχωρίσετε περισσότερες από μία ηλεκτρονικές διευθύνσεις, χωρισμένες με κόμμα</div>
              <div v-if="applicationValidators.email" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mb-3 ps-lg-2">
            <div class="form-floating">
              <input @input="check('phone')" v-model="application.phone" type="number" ref="phone" class="form-control" id="float106" placeholder="name@example.com">
              <label for="float106">Tηλέφωνα επαφής</label>
              <div class="form-text text-muted">Μπορείτε να καταχωρίσετε περισσότερα από ένα νούμερα, χωρισμένα με κόμμα</div>
              <div v-if="applicationValidators.phone" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
            </div>
          </div>
        </div>
          <div class="form-floating mb-3">
            <input @input="check('address')" v-model="application.address" type="text" ref="address" class="form-control" id="float199" placeholder="name@example.com">
            <label for="float199">Διεύθυνση</label>
            <div v-if="applicationValidators.address" class="form-text text-danger">Υποχρεωτικό πεδίο</div>
          </div>

        <div v-if="this.res" class="alert alert-primary alert-dismissible fade show" role="alert">
          Λάβαμε την αίτηση σας με επιτυχία. Θα επικοινωνήσουμε μαζί σας συντομα.
          <button @click="res = null" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
        </div>
        <div v-else-if="this.res === false" class="alert alert-warning alert-dismissible fade show" role="alert">
          Δεν μπορέσαμε να λάβουμε την αίτηση σας. Παρακαλώ προσπαθήστε ξανά.
          <button @click="res = null" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
        </div>
        <div v-if="this.feedback === true" class="alert alert-warning alert-dismissible fade show" role="alert">
          Παρακαλώ συμπληρώστε όλα τα πεδία, εισάγετε ένα έγκυρο Email και αποδεχτείτε τους όρους συμμετοχής.
          <button @click="feedback = false" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button @click="res = null, feedback = false" type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
        <button v-if="!sending" @click="send" type="submit" class="btn btn-sm btn-primary">Αποστολή</button>
        <button v-else-if="sending" class="btn btn-sm btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Αποστολή..
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      res: '',
      feedback: '',
      sending: '',

      applicationValidators: {
        nameChild: '',
        birthday: "",
        currentClass: "",
        nameFather: "",
        nameMother: "",
        email: "",
        phone: "",
        address: ""
      },

      application: {
        nameChild: '',
        birthday: "",
        currentClass: "",
        nameFather: "",
        nameMother: "",
        email: "",
        phone: "",
        address: ""
      }
    }
  },
  methods: {
    check(prop) {
      try {
        var state = true
        if (eval(`this.application.${prop}`) === "") {
          new Function(eval(`this.applicationValidators.${prop} = true`))
          this.$refs[prop].classList.add("is-invalid")
          this.$refs[prop].classList.remove("is-valid")
          state = false
          return false
        } else {
          new Function(eval(`this.applicationValidators.${prop} = false`))
          this.$refs[prop].classList.remove("is-invalid")
          this.$refs[prop].classList.add("is-valid")
          state = true
          return state
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    },
    send() {
      try {
        var sendable = true
        for (var prop in this.applicationValidators) {
          var check = this.check(prop)
          if (check === false) sendable = false
        }
        if (sendable) {
          this.sending = true
          this.feedback = false
          axios.post("/api/schoolApplication", {
            application: this.application
          }).then((res) => {
            this.sending = false
            res.data === true ? this.res = true : this.res = false
            for (var a in this.application) {
              this.application[a] = ''
            }
            for (var b in this.applicationValidators) {
              this.$refs[b].classList.remove("is-invalid")
              this.$refs[b].classList.remove("is-valid")
            }
          }).catch(error => this.$store.commit("LOG_EVENT", error))
        }
      } catch (error) {
        this.res = false
        this.$store.commit("LOG_EVENT", (error))
      }
      }
    },
}
</script>
