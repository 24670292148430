<template>
  <div>
    <div class="row m-0 me-2 ms-2 mb-5">
      <div class="container p-0 m-0">

          Το <strong>ελληνικό σχολείο της Βέρνης</strong> αριθμεί <strong>68</strong> μαθητές. Πρόκειται για παιδιά που προέρχονται από διαφορετικά γλωσσικά περιβάλλοντα και έχουν
          διαφορετική σχέση με τα ελληνικά. Σε αυτή ακριβώς την ιδιαιτερότητα καλείται να ανταποκριθεί το σχολείο μας και να καλύψει τις διαφορετικές ανάγκες των μαθητών μας.
          <br><br>
          Το ελληνικό σχολείο διαθέτει <strong>τμήματα προσχολικής αγωγής</strong> καθώς και <strong>τμήματα δημοτικού</strong>. Στόχος του ελληνικού σχολείου είναι τόσο η εκμάθηση της <strong>ελληνικής γλώσσας</strong> στα παιδιά όσο και η διδασκαλία <strong>ιστορίας</strong>, <strong>μυθολογίας</strong>,
          καθώς και η γνωριμία με τα ήθη, έθιμα και τις παραδόσεις του τόπου μας. Μεγάλη βάση δίνεται επίσης και στη δημιουργία σχέσεων φιλίας και συνεργασίας μεταξύ των παιδιών.
          Τα μαθηματα πραγματοποιουνται εβδομαδιαία ως εξης:
          <br><br>
          <TableSchool />
          <br>
          Οι στόχοι αυτοί δε δύναται να επιτευχθούν χωρίς τις τέσσερις <a href="#teacherBios">δασκάλες</a> μας, οι οποίες με τον ενθουσιασμό τους και την επιστημoνική τους συγκρότηση φροντίζουν όχι
          μόνο για τη μετάδοση των γνώσεων αλλά και για την καλλιέργεια αξιών όπως ο σεβασμός, η εμπιστοσύνη, η αγάπη και η αλληλεγγύη ως αποραίτητη προϋπόθεση για την επιτυχία και ευτυχία.
          <br><br>
          Για την <strong>εγγραφή</strong> και <strong>ερωτήσεις</strong> σχετικά με το σχολείο, συμπληρώστε τις παρακάτω διαδικτυακές φόρμες.
         <br><br>
         <button class="btn btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#schoolForm">Εγγραφή στο Σχολείο</button>
         <button class="btn btn btn-outline-success me-2" data-bs-toggle="modal" data-bs-target="#contactModal">Επικοινωνία</button>
        </div>
      </div>
        <SchoolSub />
        <SchoolNews />
        <SchoolForm />
  </div>
</template>

<script>
import SchoolForm from '../components/SchoolForm'
import TableSchool from '../components/TableSchool'
import SchoolSub from '../components/SchoolSub'
import SchoolNews from '../components/SchoolNews'
export default {
  components: {
    TableSchool,
    SchoolSub,
    SchoolNews,
    SchoolForm
  }
}
</script>
