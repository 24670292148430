<template>
    <div class="row p-0 m-0" style="height: 210px; text-align: center; margin: auto;">
      <ul class="mt-4" style="list-style: none;">
        <li>Developed and maintained by Aris Konstantinidis <a href="mailto:konstantinidis.aris95@gmail.com">konstantinidis.aris95@gmail.com</a></li>
        <li>&copy; Copyright {{new Date().getFullYear()}}, Ελληνική Κοινότητα Βέρνης <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#contactModal">Επικοινωνία</a>, <a href="https://www.facebook.com/groups/grgebe">Facebook</a> <a v-if="!auth" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#adminModal">Διαχείριση</a>
        <a v-else-if="auth" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#administratorControl">Διαχειριστής</a></li>
        <br>
        <li>Valiant Bank AG, 3011 Bern</li>
        <li>Griechische Gemeinde Bern</li>
        <li>IBAN Nr.: CH29 0630 0016 9351 0980 5</li>
        <li>Konto Nr.: 30-38112-0</li>
      </ul>
      <AdminPage v-if="auth" />
    </div>
</template>

<script>
import axios from 'axios'
import AdminPage from '../components/AdminPage'
export default {
  components: {
    AdminPage
  },
  computed: {
    auth() {
      return this.$store.state.auth
    }
  },
  methods: {
    verify() {
      try {
        if (localStorage.getItem("token")) {
          axios.post("/api/auth", { token: localStorage.getItem("token") }).then(({data}) => {
            if (data === true) {
              this.$store.commit("SET_AUTH", true)
            } else {
              this.$store.commit("SET_AUTH", false)
            }
          }).catch(error => this.$store.commit("LOG_EVENT", error))
        }
      } catch (error) {
        this.$store.commit("LOG_EVENT", (error))
      }
    }
  },
  mounted() {
    try {
      this.verify()
      this.$root.$on("auth", () => {
        this.verify()
      })
    } catch (error) {
      this.$store.commit("LOG_EVENT", (error))
    }
  }
}
</script>
